const AREA_CONFIG = window.AREAS;

function GET_AREA_CONFIG_INFO(options) {
  if (!options.id) {
    return null;
  }

  const info = {
    id: options.id,
    name: '未知',
    pid: 0,
  };

  Object.assign(info, _.find(AREA_CONFIG, {
    id: options.id,
  }));
  return info;
}

export {
  AREA_CONFIG,
  GET_AREA_CONFIG_INFO,
};
