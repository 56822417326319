import { DICT } from './dict';


const TOO_MANY_REQUESTS = 429;
const TOO_MANY_TIPS = '操作频率太快，请不要频繁访问。';
function getToken() {
  let tokenData = '';

  try {
    tokenData = $.cookie(DICT.TOKEN_KEY);
  }
  catch (err) {
    tokenData = '';
  }

  return tokenData;
}

const cookieOption = {
  // expires: 7,
  path: '/',
};

if (process.env.ROOT_DOMAIN && -1 < document.domain.indexOf(process.env.ROOT_DOMAIN)) {
  cookieOption.domain = process.env.ROOT_DOMAIN;
}

// $.ajaxPrefilter((options, originalOptions, jqXHR) => {
$.ajaxPrefilter((options) => {
  // window.console.log('options', options, 'originalOptions', originalOptions, 'jqXHR', jqXHR);

  const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    ...options.headers,
  };

  options.xhrFields = {
    withCredentials: true,
  }

  const tokenData = getToken();

  if (tokenData && !headers.Authorization) {
    headers.Authorization = `Bearer ${tokenData}`;
  }

  // headers.time = 'time';
  // headers.sign = 'sign';
  // headers['nonce-str'] = 'nonce-str';

  const success = options.success;
  const error = options.error;
  const successWarp = (data, textStatus, cbJqXHR) => {
    // window.console.log('successWarp', data, textStatus, cbJqXHR);
    if (TOO_MANY_REQUESTS === _.get(data, 'code')) {
      window.alert(_.get(data, 'msg') || TOO_MANY_TIPS);
      return success(data, textStatus, cbJqXHR);
    }

    if (401 === _.get(data, 'code')) {
      jQuery(window).trigger(DICT.EVENT.CAS_AUTH_401, data);
    }

    if ('function' === typeof success) {
      return success(data, textStatus, cbJqXHR);
    }
  };


  const errorWarp = (cbJqXHR, textStatus, errorThrown) => {
    window.console.log('errorWarp', cbJqXHR, textStatus);
    if (TOO_MANY_REQUESTS === _.get(cbJqXHR, 'status') || TOO_MANY_REQUESTS === _.get(cbJqXHR, 'responseJSON.code')) {
      window.alert(_.get(cbJqXHR, 'responseJSON.msg') || TOO_MANY_TIPS);
    }
    // window.console.log('errorWarp', cbJqXHR, textStatus, errorThrown);
    if ('function' === typeof error) {
      return error(cbJqXHR, textStatus, errorThrown);
    }
  };

  Object.assign(options, {
    success: successWarp,
    error: errorWarp,
    headers: {
      ...headers,
    },
  });
});

export {
  cookieOption,
  getToken,
};
