/* eslint-disable */
if ('production' === process.env.NODE_ENV) {
  var _hmt = _hmt || [];
  function baiduStat() {
    var hm = document.createElement("script");
    hm.src = `//hm.baidu.com/hm.js?${process.env.BAIDU_TONGJI_KEY}`;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  };
  baiduStat();
}
/* eslint-enable */
