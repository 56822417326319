function formatDICT(obj) {
  _.each(obj, (val) => {
    if (_.isEmpty(val)) {
      return false;
    }
    if (!_.isArray(val) && _.isObject(val)) {
      formatDICT(val);
    }
    if (_.isArray(val)) {
      const TEMP_DICT = val;
      for (const item of TEMP_DICT) {
        if (!_.isEmpty(item.key)) {
          Object.defineProperty(TEMP_DICT, item.key, {
            value: item.value,
            enumerable: false,
            configurable: false,
            writable: false,
          });
        }
      }
    }
  });
}
if (!window.CONST_DICT) {
  let CONST_DICT = {};
  try {
    CONST_DICT = JSON.parse(localStorage.getItem('CONST_DICT'));
  }
  catch (err) {
    CONST_DICT = {};
  }
  window.CONST_DICT = CONST_DICT;
}
else {
  try {
    localStorage.setItem('CONST_DICT', JSON.stringify(window.CONST_DICT));
  }
  catch (err) {
    // do nothing
  }
}

formatDICT(window.CONST_DICT);
