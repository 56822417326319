$(() => {
  if (!$('#js-header').length) {
    return;
  }

  const height = $('#js-header').offset().top + $('#js-header').height();
  if ($(window).scrollTop() > height) {
    $('.back-to-top').show();
  }
  else {
    $('.back-to-top').hide();
  }
  $('.back-to-top').click((e) => {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0,
    }, 800);
  });
  $(window).on('scroll', () => {
    if ($(window).scrollTop() > height) {
      $('.back-to-top').show();
    }
    else {
      $('.back-to-top').hide();
    }
  });
  $('.js-go-to:not(".back-to-top")').on('click', (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const target = $($(e.target).data('target'));
    $('html, body').animate({
      scrollTop: target.offset().top,
    }, 800);
  });
});
