import { DICT } from './dict';
import { cookieOption, getToken } from './http';
import { GET_AREA_CONFIG_INFO } from './area';
import download from './download';
import showAnnouncement from './show_announcement.js';

/**
 * Header
 */

/**
 * 事件监听
 */
let enentInit = false;
$(() => {
  if (enentInit) {
    return;
  }
  enentInit = true;
  // 跳转到普通用户登录页面
  $(window).on(DICT.EVENT.CAS_JUMP_AUTH, () => {
    $.removeCookie(DICT.TOKEN_KEY, cookieOption);
    let callbackHref = window.location.href;
    callbackHref = callbackHref.replace(/#*?&*?ctrl_d=([\d-]+)/ig, '').replace(/#$/ig, '').replace(/\?$/ig, '');
    // eslint-disable-next-line camelcase
    const redirect_uri = encodeURIComponent(callbackHref);
    // eslint-disable-next-line camelcase
    return window.location.replace(`${DICT.API_DOMAIN_WITH_PREFIX}/redirect?type=user&redirect_uri=${redirect_uri}`);
  });

  // 跳转到管理员登录页面
  $(window).on(DICT.EVENT.CAS_JUMP_ADMIN_AUTH, () => {
    $.removeCookie(DICT.TOKEN_KEY, cookieOption);
    let callbackHref = window.location.href;
    callbackHref = callbackHref.replace(/#*?&*?ctrl_d=([\d-]+)/ig, '').replace(/#$/ig, '').replace(/admin=true/ig, '').replace(/\?$/, '');
    // eslint-disable-next-line camelcase
    const redirect_uri = encodeURIComponent(callbackHref);
    // eslint-disable-next-line camelcase
    const url = `${DICT.API_DOMAIN_WITH_PREFIX}/redirect?type=admin&redirect_uri=${redirect_uri}`;
    // window.console.log('url', url, 'callbackHref', callbackHref);
    return window.location.replace(url);
  });

  $(window).on(DICT.EVENT.CAS_LOGOUT, (e, options = {}) => {
    sessionStorage.clear();
    const token = getToken();
    // localStorage.clear();
    $.removeCookie(DICT.TOKEN_KEY, cookieOption);
    let callbackHref = options.href || window.location.href;
    callbackHref = callbackHref.replace(/#*?&*?ctrl_d=([\d-]+)/ig, '').replace(/#$/ig, '').replace(/\?$/ig, '');
    // eslint-disable-next-line camelcase
    const redirectUri = encodeURIComponent(callbackHref);

    const jumpUrl = `${DICT.API_DOMAIN_WITH_PREFIX}/logout/redirect?redirect_uri=${redirectUri}`;

    if (['true', true].includes(process.env.LOGOUT_METHOD_POST_WITH_TOKEN_AUTH)) {
      return download(jumpUrl, {
        token,
        api_token: token,
        format: 'api',
      }, {
        target: '_self',
      });
    }

    // eslint-disable-next-line camelcase
    return window.location.replace(jumpUrl);
  });

  $(window).on(DICT.EVENT.CAS_LOGIN, (e, User) => {
    sessionStorage.setItem('HAS_LOGIN', true);
    showAnnouncement(User);
  });

  $(window).on(DICT.EVENT.CAS_CALLBACK, () => {
    return window.location.replace(`${DICT.API_DOMAIN_WITH_PREFIX}/authorize?${window.location.search.replace(/\?/, '')}`);
  });

  // 监听全局提示请求状态 401 的事件，此时直接提醒是否重新授权
  window.modal401 = undefined;
  jQuery(window).on(DICT.EVENT.CAS_AUTH_401, (e, data) => {
    if ('develop' === process.env.NODE_ENV && data) {
      window.console.log('CAS_AUTH_401 data', data);
    }

    const closeModel = () => {
      if (window.modal401 && window.modal401.destroy) {
        window.modal401.destroy();
      }
    };

    closeModel();

    const onOk = () => {
      jQuery(window).trigger(DICT.EVENT.CAS_LOGOUT);
    };

    const onCancel = () => {
      closeModel();
    };
    // localStorage.clear();

    window.modal401 = antd.Modal.confirm({
      title: '登录失效',
      content: (<div>
        <div>当前登录状态已经失效，请重新登录。</div>
      </div>),
      footer: null,
      okText: '重新登录',
      cancelText: '关闭',
      onOk,
      onCancel,
    });
  });
});

let clickInit = false;
$(() => {
  if (clickInit) {
    return;
  }
  clickInit = true;
  function ticketLoginError(rej) {
    let msg = '登录失败，请重试';
    if (rej && rej.msg) {
      msg = rej.msg;
      if ('invalid ticket' === rej.msg) {
        msg = 'ticket 错误，登录失败';
      }
    }

    // eslint-disable-next-line no-alert
    window.alert(msg);
    return repalceTicket();
  }
  function getTicketToken(ticket) {
    $.ajax({
      url: `${DICT.API_DOMAIN_WITH_PREFIX}/token/${ticket}`,
      type: 'get',
      async: false,
      success: (res) => {
        // window.console.log('res', res);
        if (res && 0 === res.code && res.data && res.data.token) {
          const token = _.get(res, 'data.token');
          $.cookie(DICT.TOKEN_KEY, token, cookieOption);

          if (-1 < document.cookie.indexOf('autoJumpWebsite=false')) {
            document.cookie = 'autoJumpWebsite=true';
            // 跳转到 /user 不需要 replace ticket
            return repalceTicket();
          }
          else if (process.env.TICKET_LOGIN_SUCCESS_JUMP_URL) {
            setTimeout(() => {
              location.href = `${process.env.TICKET_LOGIN_SUCCESS_JUMP_URL}`;
            }, 100);
            return true;
          }
          else if (!/\/user$/.test(location.pathname)) {
            location.href = `${(process.env.WEBSITE_WEB_PREFIX || '').replace(/\/$/, '')}/user`;
            return true;
          }
          else {
            // 跳转到 /user 不需要 replace ticket
            return repalceTicket();
          }
        }
        else {
          return ticketLoginError(res);
        }
      },
      error: (rej) => {
        ticketLoginError(rej);
        window.console.log('rej', rej);
      },
    });
  }

  const ticketRegex = /\?*?&?ticket=([0-9a-z]+)/gi;

  function repalceTicket() {
    window.history.pushState(null, null, location.href.replace(ticketRegex, ''));
    // 兼容replace之后 没有刷新页面
    window.location.reload();
  }

  function getUserInfo() {
    const User = {};
    $.ajax({
      url: `${DICT.API_DOMAIN_WITH_PREFIX}/home/user`,
      type: 'GET',
      success: (res) => {
        // window.console.log('getUserInfo user', res);
        const hasLogin = sessionStorage.getItem('HAS_LOGIN');
        if (res && res.data && res.data.id) {
          if (!hasLogin) {
            $(window).trigger(DICT.EVENT.CAS_LOGIN, res.data);
          }
          // 管理员，显示控制台
          if ([
            _.get(CONST_DICT, 'users.user_type.USER_TYPE_SUPER'),
            _.get(CONST_DICT, 'users.user_type.USER_TYPE_CITY'),
            _.get(CONST_DICT, 'users.user_type.USER_TYPE_DISTRICT'),
            _.get(CONST_DICT, 'users.user_type.USER_TYPE_SCHOOL'),
          ].includes(_.get(res, 'data.user_type'))) {
            $('.header-logined-nav-item-admin').removeClass('hide').css({
              visibility: 'visible',
            });
            $('.header-need-login-nav-item').addClass('hide');
          }
          // 非管理员，显示个人中心
          else {
            $('.header-logined-nav-item-user').removeClass('hide').css({
              visibility: 'visible',
            });
            $('.header-need-login-nav-item').addClass('hide');
          }

          // 设置机构名称
          if (_.get(res, 'data.org_department.name')) {
            $('.header-total-nav-logo-title-department').text(`${_.get(res, 'data.org_department.name')}空间`);
          }
          else {
            $('.header-total-nav-logo-title-department').hide();
          }

          Object.assign(User, res.data);
          User.province = User.province || GET_AREA_CONFIG_INFO({ id: User.province_id });
          User.city = User.city || GET_AREA_CONFIG_INFO({ id: User.city_id });
          User.district = User.district || GET_AREA_CONFIG_INFO({ id: User.district_id });
          // eslint-disable-next-line no-underscore-dangle
          window.____User = User;
          $(window).trigger(DICT.EVENT.WEBSITE_GET_USERINFO, User);

          // 获取 用户类型
          getUserTypes();
        }

        else {
          // 这里不处理了。

          // $.removeCookie(DICT.TOKEN_KEY, cookieOption);
          // $(window).trigger(DICT.EVENT.WEBSITE_GET_USERINFO_FAIL);
          if (window.console.log) {
            window.console.log('rej res', res);
          }

          $('.header-need-login-nav-item').css({
            visibility: 'visible',
          });
        }
      },
      error: (rej) => {
        $.removeCookie(DICT.TOKEN_KEY, cookieOption);
        $(window).trigger(DICT.EVENT.WEBSITE_GET_USERINFO_FAIL);
        if (window.console.log) {
          window.console.log('rej', rej);
        }

        $('.header-need-login-nav-item').css({
          visibility: 'visible',
        });
      },
    });
  }
  function getUserTypes() {
    let userTypes = [];
    $.ajax({
      url: `${DICT.API_DOMAIN_WITH_PREFIX}/user/user_types`,
      type: 'GET',
      success: (res) => {
        if (res && 0 === res.code && res.data) {
          const dictUserType = _.get(CONST_DICT, 'users.user_type');
          userTypes = _.map(res.data, (item) => {
            return dictUserType.find((info) => {
              return info.value === item;
            });
          });
          $(window).trigger(DICT.EVENT.WEBSITE_GET_USER_TYPE, [userTypes]);
        }
      },
      error: () => {

      },
    });
  }
  const ticketMatch = ticketRegex.exec(location.href);
  if (ticketMatch && ticketMatch[1]) {
    getTicketToken(ticketMatch[1]);
  }
  /**
   * 判断是否登录，已登录就获取当前用户信息
   */
  else if ($.cookie(DICT.TOKEN_KEY)) {
    getUserInfo();
  }
  else {
    $('.header-need-login-nav-item').css({
      visibility: 'visible',
    });
    $.removeCookie(DICT.TOKEN_KEY, cookieOption);
    setTimeout(() => {
      $(window).trigger(DICT.EVENT.WEBSITE_TOKEN_EMPTY);
    }, 100);
    setTimeout(() => {
      $(window).trigger(DICT.EVENT.WEBSITE_TOKEN_EMPTY);
    }, 1000);
  }
  /**
   * 普通用户登录注册按钮
   */
  $('.header-auth-nav-item-action-go-user').on('click', (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return $(window).trigger(DICT.EVENT.CAS_JUMP_AUTH);
  });

  /**
   * 管理员 如果直接带 admin=true 参数，就直接登录管理员
   */
  if (/admin=true/.test(location.search)) {
    antd.message.info('即将跳转管理员登录页面');
    setTimeout(() => {
      return $(window).trigger(DICT.EVENT.CAS_JUMP_ADMIN_AUTH);
    }, 100);
  }

  /**
   * 管理员登录注册按钮
   */
  $('.header-auth-nav-item-action-go-admin').on('click', (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return $(window).trigger(DICT.EVENT.CAS_JUMP_ADMIN_AUTH);
  });


  /**
   * 退出按钮
   */
  $('.header-auth-nav-item-action-logout, .user-sidebar-left-list-item-logout').on('click', (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    $(window).trigger(DICT.EVENT.CAS_LOGOUT, {
      href: `${location.origin.replace(/\/$/, '')}${process.env.WEBSITE_PORTAL_INDEX}`,
    });
  });
});
